body {
    font-family: 'Open Sans Variable', sans-serif;
    color: #575757;
}

.indexCarouselItem {
    z-index: -99;
    position: fixed;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}


.cover-container {
    z-index: 1000;
    max-width: 50em;
}

#auth-content {
    width: 30em;
}

/* hide sidebar scrollbar on Chrome-based browsers */
#sidebarMenu::-webkit-scrollbar {
    display: none;
}


/* sidebar */
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
}


/* bootstrap svg icons */
.bi {
    fill: #575757;
}

/* footer text */
.text-muted {
    color: #575757;
}


/* tall logo fonts */
#tall-logo {
    letter-spacing: .08em;
    color: #575757;
    height: 1em;
    font-size: 4.1em;
}

#tall-logo-desc {
    letter-spacing: -.01em;
    color: #575757;
    font-size: .83em;
}

#collection-logo {
    letter-spacing: .15em;
    color: #575757;
}

/* search bar */
input[type=search] {
    border: none;
    border-radius: 0px;
    border-bottom: 2px solid grey;
}

#search-button {
    border: none;
    border-radius: 0px;
}


/* collapse if media larger than xl */
/* @media (max-width: 1200px) and (min-height: 840px) {
    #material-category-collapse.collapse {
        display: block;
    }
} */


/* overwrite button colors and layouts */
#material-category-btn, #material-track-btn, #material-classification-btn, #advanced-filter-btn {
    color: #575757;
}

/* collapsed items under material category button */
[name=category] {
    color: #a1a1a1;
}


[id^="track-"] {
    border-radius: 0;
    text-align: left;
    letter-spacing: .1em;
}



li > div > a.btn {
    border-radius: 0;
    text-align: left;
}



li > div > button.btn.dropdown-toggle {
    border-radius: 0;
    text-align: right;
}


/* groupselect optgroup font */
optgroup {
    font-style: normal;
}


/* filter modal edges */
.modal-content {
    border-radius: 0;
}


/* querystring buttons */
[name="querystringButton"].btn {
    color: #a1a1a1;
}


/* card colors and placement */
.card {
    color: #575757;
}

h1.text-uppercase {
    letter-spacing: .08em;
}

h2.text-uppercase, .h5.text-uppercase, h4.text-uppercase {
    letter-spacing: .1em;
    /* color: #575757; */
}

[class|=bg-tall].text-white.my-2 {
    letter-spacing: .12em;
}

#material-name {
    min-height: 4em;
}

#material-category {
    min-height: 9em;
}

#material-description {
    min-height: 10em;
}


/* material track highlights */
span.mx-1.h5 {
    letter-spacing: .05em;
}

#description-summary {
    font-family: 'Open Sans Variable', sans-serif;
    font-weight: 300
}

/* description fonts (detail view) */
p.h6{
    font-family: 'Open Sans Variable', sans-serif;
    font-weight: 300;
}

p.h5 {
    font-family: 'Open Sans Variable', sans-serif;
    font-weight: 300;
}

h7 {
    font-size: 14px;
    color: #a1a1a1;
}


.page-link {
    color: #575757;
}

.page-item.active .page-link {
    background-color: #575757;
    border-color: #575757;
}

[name="iteration-url"] {
    color: #575757;
}


/* Google Map on Detail Pages */
#map {
    height: 400px;
    width: 100%;
}

#enableMapModal {
    color: #575757;
}


/* change opacity of thumbnails on hover */
.tutorial-thumbnails {
    opacity: 0.75;
}

.tutorial-thumbnails:hover {
    opacity: 0.25;
}
